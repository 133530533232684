<app-menu [moduloEnum]="moduloEnum" [funcionalidadeEnum]="funcionalidadeEnum"></app-menu>

<div class="container">
    <form class="form" [formGroup]="model"  (ngSubmit)="submit()">
        <div class="row">
            <div class="col-12">
                <h1 class="title-default">Informações da Empresa</h1>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Nome Empresa</mat-label>
                    <input matInput formControlName="nomeEmpresa" type="text">
                    @if (model.get('nomeEmpresa')?.value == "") {
                        <mat-error>Informe o nome da empresa</mat-error>
                    }
                </mat-form-field>
            </div>

            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>CNPJ</mat-label>
                    <input matInput formControlName="cnpj" type="text" [mask]="masks.cnpj">
                    @if (model.get('cnpj')?.invalid) {
                        <mat-error>Informe o CNPJ</mat-error>
                    }
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Timezone</mat-label>
                    <mat-icon class="material-icons-outlined" matSuffix matTooltip="Seleciona o timezone da sua localidade">info_outline</mat-icon>
                    <input type="text"
                           placeholder="Selecione um timezone"
                           aria-label="Timezone"
                           matInput
                           formControlName="listaTimezone"
                           [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let timezone of listaTimezoneFiltrado | async" [value]="timezone.nome">
                            {{ timezone.nome }}
                        </mat-option>
                    </mat-autocomplete>
                    @if (model.get('listaTimezone')?.invalid) {
                        <mat-error>Seleciona o seu fuso horário</mat-error>
                    }
                </mat-form-field>
            </div>
        </div>

        <div class="row margin-top-bottom-0">
            <div class="col-12">
                <h1 class="subtitle-default">Configuração de acesso ao banco de dados do Resulth local</h1>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>URL da API de acesso ao banco de dados do Resulth local</mat-label>
                    <input matInput formControlName="urlApiAcessoBDResulthLocal" type="text">
                    @if (model.get('urlApiAcessoBDResulthLocal')?.invalid) {
                        <mat-error>Informe a URL da API de acesso ao banco de dados do Resulth local da empresa</mat-error>
                    }
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Caminho do banco de dados do Resulth local</mat-label>
                    <input matInput formControlName="caminhoBDResulthLocal" type="text">
                    @if (model.get('caminhoBDResulthLocal')?.invalid) {
                        <mat-error>Informe o caminho do banco de dados do Resulth local da empresa</mat-error>
                    }
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <mat-form-field appearance="outline">
                    <mat-label>Usuário do banco de dados do Resulth local</mat-label>
                    <input matInput formControlName="usuarioBDResulthLocal" type="text">
                    @if (model.get('usuarioBDResulthLocal')?.invalid) {
                        <mat-error>Informe o usuário do banco de dados do Resulth local da empresa</mat-error>
                    }
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <mat-form-field appearance="outline">
                    <mat-label>Senha do banco de dados do Resulth local</mat-label>
                    <input matInput formControlName="senhaBDResulthLocal" type="password" class="password-input">
                    @if(model.get('senhaBDResulthLocal')?.invalid) {
                        <mat-error>Informe a senha do banco de dados do Resulth local da empresa</mat-error>
                    }
                    <mat-icon class="password-locked" (click)="openModal()">lock</mat-icon>
                </mat-form-field>
            </div>

            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>ID da filial do banco de dados do Resulth local</mat-label>
                    <input matInput formControlName="codFilialBDResulthLocal" type="text" maxlength="2" (input)="somenteNumeros($event)" (blur)="formatandoCampo()">
                    @if(model.get('codFilialBDResulthLocal')?.invalid) {
                        <mat-error>Informe o ID da filial do banco de dados do Resulth local</mat-error>
                    }
                </mat-form-field>
            </div>

            <div class="col-md-2">
                <button type="button" mat-flat-button class="btn-primary button-big button-conexao button-responsivo" [disabled]="buttonDesativado" (click)="testarConexao()">
                    @if(!_isLoadingButtonTestarConexao){
                        Testar conexão
                    } @else {
                        <mat-spinner diameter="20"></mat-spinner>
                    }
                </button>
            </div>

            <div class="col-md-3">
                <button type="submit" mat-flat-button class="btn-primary button-big button-responsivo" [disabled]="buttonDesativado">
                    @if(!_isLoadingButtonSalvar){
                        Salvar
                    } @else {
                        <mat-spinner diameter="20"></mat-spinner>
                    }
                </button>
            </div>
        </div>
    </form>
</div>