import { Component } from '@angular/core';
import { MenuComponent } from "../../../shared/components/menu/menu.component";
import { ModuloEnum } from '../../../shared/enums/modulo.enum';
import { FuncionalidadeEnum } from '../../../shared/enums/funcionalidade.enum';

@Component({
    selector: 'app-home',
    imports: [MenuComponent],
    templateUrl: './home.component.html',
    styleUrl: './home.component.css'
})
export class HomeComponent {
  public moduloEnum:ModuloEnum;
  public funcionalidadeEnum:FuncionalidadeEnum

  constructor(){
    this.moduloEnum = ModuloEnum.Home
    this.funcionalidadeEnum = FuncionalidadeEnum.Master_Usuario
  }

}
