import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuComponent } from '../../../shared/components/menu/menu.component';
import { ModuloEnum } from '../../../shared/enums/modulo.enum';
import { FuncionalidadeEnum } from '../../../shared/enums/funcionalidade.enum';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatTableDataSource, MatTableModule} from '@angular/material/table';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { LoadingBoxComponent } from "../../../shared/components/loading-box/loading-box.component";
import { ConfirmModalComponent } from '../../../shared/components/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { RouterModule } from '@angular/router';
import { EmptyListComponent } from "../../../shared/components/empty-list/empty-list.component";
import { MatTooltipModule } from '@angular/material/tooltip';
import { PerfilService } from '../../../shared/services/apis/usuario/perfil.service';
import { ListaPerfilResponse } from '../../../shared/services/responses/usuario/lista-perfil.response';
import { ListaPerfilStruct } from '../../../shared/services/structs/usuario/lista-perfil.struct';

@Component({
    selector: 'app-usuario-lista',
    imports: [
        MenuComponent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        CommonModule,
        MatTableModule,
        LoadingBoxComponent,
        RouterModule,
        EmptyListComponent,
        MatTooltipModule
    ],
    templateUrl: './perfil-lista.component.html',
    styleUrl: './perfil-lista.component.css'
})
export class PerfilListaComponent implements OnInit{
  public moduloEnum:ModuloEnum;
  public funcionalidadeEnum:FuncionalidadeEnum

  constructor(private formBuilder: FormBuilder,
    private perfilService:PerfilService,
    private alertService: AlertService,
    private dialog: MatDialog,
  ){
    this.moduloEnum = ModuloEnum.Master
    this.funcionalidadeEnum = FuncionalidadeEnum.Master_Perfil
  }

  public model: FormGroup;
  public _isLoading:boolean = false;
  public _listaPerfilResponse:ListaPerfilResponse|undefined;
  public displayedColumns: string[] = ['idPerfil', 'nomePerfil', 'descricao', "action-edit", "action-delete"];
  public _dataSource = new MatTableDataSource<ListaPerfilStruct>();
  public _paginaAtual:number = 0;
  public _ultimaPagina:boolean = false
    
  ngOnInit(): void {
    this.model = this.formBuilder.group({
      pesquisa: [''],
    });

    this.pesquisar();
  }

  private pesquisar(){
    if( this.model.invalid || this._isLoading == true)
      return;

    this._isLoading = true;
    let pesquisa:string = this.model.get('pesquisa')?.value;

    this.perfilService.get(pesquisa, this._paginaAtual).subscribe({
      next:(response: ListaPerfilResponse) => {
                
        this._isLoading = false;
        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          return;
        }

        this._ultimaPagina = true;

        if(this._listaPerfilResponse == undefined){
          this._listaPerfilResponse = response;
          this._ultimaPagina = false;
        }
        else if(response.listaPerfil?.length > 0){
          this._listaPerfilResponse.listaPerfil.push(...response.listaPerfil);
          this._ultimaPagina = false;
        }

        this._dataSource.data = this._listaPerfilResponse.listaPerfil
      },
      error:(error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this._isLoading = false;
      }  
    });
  }

  proximaPagina(){
    this._paginaAtual = this._paginaAtual+1;
    this.pesquisar();
  }

  clickPesquisar(){
    this._paginaAtual = 0;
    this._listaPerfilResponse = undefined;
    this.pesquisar();
  }

  abrirModalExcluir(idPerfil:number, nomePerfil:string): void{
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: "Alerta",
        description: `Deseja excluir o perfil ${nomePerfil}? Essa ação será irreversível.`,
        buttonConfirm: "Confirmar",
        buttonClose: "Cancelar"
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.confirm) 
          this.excluir(idPerfil);
      }
    });
  }

  private excluir(idPerfil:number){
    this.perfilService.excluir(idPerfil).subscribe({
      next: (returnStruct:ReturnStruct) => {
         
        this._isLoading = false;
        if (returnStruct.isError) {
          this.alertService.show("Erro", returnStruct.errorDescription, AlertType.error);
          return;
        }

        this.clickPesquisar();

        this.alertService.show("Sucesso", "Perfil excluido com sucesso", AlertType.success);
      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this._isLoading = false;
      }
    });
  }


}