import { Component } from '@angular/core';
import { ModuloEnum } from '../../../shared/enums/modulo.enum';
import { MenuComponent } from '../../../shared/components/menu/menu.component';
import { FuncionalidadeEnum } from '../../../shared/enums/funcionalidade.enum';

@Component({
    selector: 'app-master-home',
    imports: [
        MenuComponent
    ],
    templateUrl: './master-home.component.html',
    styleUrl: './master-home.component.css'
})
export class MasterHomeComponent {
  public moduloEnum:ModuloEnum;
  public funcionalidadeEnum:FuncionalidadeEnum

  constructor(){
    this.moduloEnum = ModuloEnum.Master
    this.funcionalidadeEnum = FuncionalidadeEnum.Master_Usuario
  }
}
