import { Routes } from '@angular/router';
import { AuthComponent } from './auth/pages/auth/auth.component';
import { AuthGuard } from './auth-guard.service';
import { SenhaRecuperarComponent } from './auth/pages/senha-recuperar/senha-recuperar.component';
import { SenhaAlterarComponent } from './auth/pages/senha-alterar/senha-alterar.component';
import { HomeComponent } from './home/pages/home/home.component';
import { MasterHomeComponent } from './master/pages/master-home/master-home.component';

export const routes: Routes = [
    { path: '', component: AuthComponent, pathMatch: 'full' },
    { path: 'auth/senha-recuperar', component: SenhaRecuperarComponent },
    { path: 'auth/senha-alterar/:token', component: SenhaAlterarComponent },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'master', component: MasterHomeComponent, canActivate: [AuthGuard] },
];
