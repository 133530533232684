import { Component } from '@angular/core';
import { ModuloEnum } from '../../../shared/enums/modulo.enum';
import { FuncionalidadeEnum } from '../../../shared/enums/funcionalidade.enum';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { UsuarioService } from '../../../shared/services/apis/usuario/usuario.service';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { UsuarioLookupResponse } from '../../../shared/services/responses/usuario/usuario-lookup.response';
import { MenuComponent } from "../../../shared/components/menu/menu.component";
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioRequest } from '../../../shared/services/requests/usuario/usuario.request';
import { ConfirmModalComponent } from '../../../shared/components/confirm-modal/confirm-modal.component';
import { UsuarioResponse } from '../../../shared/services/responses/usuario/usuario.response';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UsuarioVerificarEmailService } from '../../../shared/services/apis/usuario/usuario-verificar-email.service';
import { UsuarioVerificarEmailResponse } from '../../../shared/services/responses/usuario/usuario-verificar-email.response';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { Masks, MaskService } from '../../../shared/services/mask.service';

@Component({
    selector: 'app-usuario-cadastro',
    imports: [
        MenuComponent,
        FormsModule,
        MatInputModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        CommonModule,
        MatTableModule,
        MatSelectModule,
        MatTooltipModule,
        MatSlideToggleModule,
        NgxMaskDirective
    ],
    providers: [
      provideNgxMask()
    ],
    templateUrl: './usuario-cadastro.component.html',
    styleUrl: './usuario-cadastro.component.css'
})
export class UsuarioCadastroComponent {
  public moduloEnum:ModuloEnum;
  public funcionalidadeEnum:FuncionalidadeEnum

  constructor(private formBuilder: FormBuilder,
    private usuarioService:UsuarioService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private usuarioVerificarEmailService:UsuarioVerificarEmailService,
    private maskService:MaskService
  ){
    this.moduloEnum = ModuloEnum.Master
    this.funcionalidadeEnum = FuncionalidadeEnum.Master_Usuario
  }

  public model: FormGroup;
  public _isLoading:boolean = false;
  public _usuarioLookupResponse:UsuarioLookupResponse;
  public _usuarioResponse:UsuarioResponse;
  public idUsuario:number|null = null;
  public btnSalvarDisable:boolean = true;
  public nomeEmpresaLogada:string;
  public masks: Masks;


  ngOnInit(): void {
    this.masks = this.maskService.getMasks();

    if (this.activatedRoute.snapshot.paramMap.get('idUsuario'))
      this.idUsuario = parseInt(this.activatedRoute.snapshot.paramMap.get('idUsuario')??'');

    this.model = this.formBuilder.group({
      email: new FormControl({ value: '', disabled: false }).setValidators([Validators.required, Validators.email]),
      nomeUsuario: new FormControl({ value: '', disabled: true }),
      telefone: new FormControl({ value: '', disabled: true }),
      cpf: new FormControl({ value: '', disabled: true }),
      listaIdPerfil: new FormControl({ value: '', disabled: false }),
      master: new FormControl({ value: '', disabled: false }),
    });

    this.popularLookup();

    if( this.idUsuario != null){
      this.popularDadosUsuario();
    }
  }

  popularLookup(){
    this.usuarioService.lookup().subscribe({
      next: (usuarioLookupResponse:UsuarioLookupResponse) => {
        if (usuarioLookupResponse.isError) {
          this.alertService.show("Erro", usuarioLookupResponse.errorDescription, AlertType.error);
          return;
        }

        this._usuarioLookupResponse = usuarioLookupResponse;
      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  popularDadosUsuario(){
    this.usuarioService.ObterPorId(this.idUsuario?? 0).subscribe({
      next: (usuarioResponse:UsuarioResponse) => {
        if (usuarioResponse.isError) {
          this.alertService.show("Erro", usuarioResponse.errorDescription, AlertType.error);
          return;
        }

        this.model.get('nomeUsuario')?.setValue(usuarioResponse.usuario.nomeUsuario);
        this.model.get('email')?.setValue(usuarioResponse.usuario.email);
        this.model.get('telefone')?.setValue(usuarioResponse.usuario.telefone);
        this.model.get('cpf')?.setValue(usuarioResponse.usuario.cpf);
        this.model.get('listaIdPerfil')?.setValue(usuarioResponse.listaIdPerfil);
        this.model.get('master')?.setValue(usuarioResponse.masterEmpresa);

        this._usuarioResponse = usuarioResponse;

        this.model.get('email')?.disable();
        this.model.get('listaIdPerfil')?.enable();
        this.model.get('master')?.enable();
        this.btnSalvarDisable = false;

        if(usuarioResponse.emrpesaCriadora){
          this.model.get('nomeUsuario')?.enable();
          this.model.get('telefone')?.enable();
          this.model.get('cpf')?.enable();
        }
          
      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  verificaEmail(){
    let email:string = this.model.get('email')?.value;

    if(!email){
      this.alertService.show("Erro", "informe o email", AlertType.error);
      return;
    }

    this.usuarioVerificarEmailService.get(email).subscribe({
      next: (usuarioVerificarEmailResponse:UsuarioVerificarEmailResponse) => {
        if (usuarioVerificarEmailResponse.isError) {
          this.alertService.show("Erro", usuarioVerificarEmailResponse.errorDescription, AlertType.error);
          return;
        }

        if(usuarioVerificarEmailResponse.usuarioNaoExiste){
          this.alertService.show("Informação", "Email livre para ser cadastrado na sua empresa", AlertType.info);
          this.enableFields()
          return;
        }

        if(usuarioVerificarEmailResponse.podeVinularEmpresa){
          this.abrirModalVincularUsuario(email);
        }

      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  abrirModalVincularUsuario(email:string){
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: "Vincular usuário deste email a sua empresa",
        description: `Esse usuário com o email ${email} já esta cadastrado em outra empresa do seu grupo de empresas. Você quer vincular ele para a sua empresa?`,
        buttonConfirm: "Vinular a minha empresa",
        buttonClose: "Cancelar"
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.confirm) 
          this.vincularUsuario(email);
      }
    });
  }

  vincularUsuario(email:string){

    this.usuarioVerificarEmailService.post(email).subscribe({
      next: (returnStruct:ReturnStruct) => {
        if (returnStruct.isError) {
          this.alertService.show("Erro", returnStruct.errorDescription, AlertType.error);
          return;
        }

        this.alertService.show("Erro", "Usuário vinculado a sua empresa.", AlertType.success);
        this.router.navigate(['/master/usuario']);
      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }
  
  submit(){
    if (this._isLoading || this.model.invalid) {
      return;
    }

    let usuarioRequest:UsuarioRequest  = new UsuarioRequest();

    usuarioRequest.idUsuario = this.idUsuario;
    usuarioRequest.email = this.model.get("email")?.value;
    usuarioRequest.nomeUsuario = this.model.get("nomeUsuario")?.value;
    usuarioRequest.telefone = this.model.get("telefone")?.value;
    usuarioRequest.cpf = this.model.get("cpf")?.value;
    usuarioRequest.master = this.model.get("master")?.value;
    usuarioRequest.listaIdPerfil = this.model.get("listaIdPerfil")?.value? this.model.get("listaIdPerfil")?.value: [];
    
    if(this.idUsuario == null){
      this.novo(usuarioRequest);
    }
    else{
      this.alterar(usuarioRequest);
    }

  }

  private novo(usuarioRequest:UsuarioRequest):void{
    this._isLoading = true;
    this.usuarioService.Incluir(usuarioRequest).subscribe({
      next: (returnStruct:ReturnStruct) => {
        this._isLoading = false;
        if (returnStruct.isError) {
          this.alertService.show("Erro", returnStruct.errorDescription, AlertType.error);
          return;
        }

        this.alertService.show("Sucesso", "Usuário incluido com sucesso.", AlertType.success);
        this.router.navigate(['/master/usuario']);
      },
      error: (error) => {
        this._isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  private alterar(usuarioRequest:UsuarioRequest):void{
    this._isLoading = true;
    this.usuarioService.Alterar(this.idUsuario??0, usuarioRequest).subscribe({
      next: (returnStruct:ReturnStruct) => {
        this._isLoading = false;
        if (returnStruct.isError) {
          this.alertService.show("Erro", returnStruct.errorDescription, AlertType.error);
          return;
        }

        this.alertService.show("Sucesso", "Usuário incluido com sucesso.", AlertType.success);
        this.router.navigate(['/master/usuario']);
      },
      error: (error) => {
        this._isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }
  
  abrirModalAlterarSenhaUsuario(){
    if(this.idUsuario == null)
      return;

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: `Alterar senha do usuário`,
        description: `Ao alterar a senha do usuário ele irá receber, no email cadastrado, sua senha temporária. Ao fazer login ele obrigatoriamente precisa inserir a senha definitiva.`,
        buttonConfirm: "Alterar a senha",
        buttonClose: "Cancelar"
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.confirm) 
          this.alterarSenha();
      }
    });
  }

  private alterarSenha(){
    this.usuarioService.refefinirSehha(this.idUsuario).subscribe({
      next: (returnStruct:ReturnStruct) => {
        this._isLoading = false;
        if (returnStruct.isError) {
          this.alertService.show("Erro", returnStruct.errorDescription, AlertType.error);
          return;
        }

        this.alertService.show("Sucesso", "Senha redefinida com sucesso.", AlertType.success);
      },
      error: (error) => {
        this._isLoading = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  } 

  public disableFields(){
    this.model.get('nomeUsuario')?.disable();
    this.model.get('telefone')?.disable();
    this.model.get('cpf')?.disable();
    this.model.get('listaIdPerfil')?.disable();
    this.model.get('master')?.disable();
    this.btnSalvarDisable = true;
  }

  public enableFields(){
    this.model.get('nomeUsuario')?.enable();
    this.model.get('telefone')?.enable();
    this.model.get('cpf')?.enable();
    this.model.get('listaIdPerfil')?.enable();
    this.model.get('master')?.enable();
    this.btnSalvarDisable = false;
  }

}
