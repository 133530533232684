export enum PermissaoEnum
{
    Master_Usuario_Listar = 1,
    Master_Usuario_Incluir = 2,
    Master_Usuario_Excluir = 3,
    Master_Usuario_Alterar = 4,

    Master_Perfil_Listar = 5,
    Master_Perfil_Incluir = 6,
    Master_Perfil_Excluir = 7,
    Master_Perfil_Alterar = 8,

    Master_ConfigurarEmpresa_Visualizar = 11,
    Master_ConfigurarEmpresa_Alterar = 12,
}