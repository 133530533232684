export class EmpresaRequest{
   nomeEmpresa: string;
   cnpj: string;
   timezone: number;
   idEmpresaGrupo?: number;
   urlApiAcessoBDResulthLocal: string;
   caminhoBDResulthLocal: string;
   usuarioBDResulthLocal: string;
   senhaBDResulthLocal: string;
   codFilialBDResulthLocal: number;  
   timezoneNomeUtc: string;
}