@if(showActionUser){
    <div class="container-item">
        <div class="text-center user-name">
            <div class="avatar-user">
                <span class="material-symbols-outlined">account_circle</span>
            </div>
            Olá <span class="name">{{userLoginResponse.nomeUsuario}}</span>
            <span class="business-name">{{userLoginResponse.nomeEmpresa}}</span>
        </div>
      
        <div class="buttons">
            <button type="button" mat-button (click)="clickLogout()">Sair</button>
        </div>
    </div>
    <div class="container-out" (click)="clickContainerOut()"></div>
}
