import { Component, OnInit, signal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../shared/services/apis/usuario/auth.service';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { UtilService } from '../../../shared/services/util.service';
import { UsuarioLoginResponse } from '../../../shared/services/responses/usuario/usuario-login.response';
import { MatDialog } from '@angular/material/dialog';
import { ModalPrimeiroAcessoComponent } from './modal-primeiro-acesso/modal-primeiro-acesso.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SuperAcessoStruct } from '../../../shared/services/structs/usuario-admin/super-acesso.struct';
import { firstValueFrom } from 'rxjs';
import { SuperAcessoService } from '../../../shared/services/apis/usuario-admin/super-acesso.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatSelectModule
  ]
})
export class AuthComponent implements OnInit {
  public model: FormGroup;
  public isLoading: boolean = false;
  public mostrarListaEmpresa: boolean = false;
  public usuarioLoginResponse: UsuarioLoginResponse;
  public superAcessoResponse: SuperAcessoStruct;
  public token: string | null = null;
  private ip: string = '192.168.0.2';

  hide = signal(true);

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private superAcessoService: SuperAcessoService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
  ) {}

  clickEvent(event: MouseEvent) {
    this.hide.set(!this.hide());
    event.stopPropagation();
  }

  async ngOnInit(): Promise<void> {
    this.token = this.activatedRoute.snapshot.queryParamMap.get('token');
    
    this.model = this.formBuilder.group({
      email: ['',],
      senha: ['',],
      idEmpresa: ['']
    });

    if (this.token) {
      const isSuccess = await this.validaTokenSuperAcesso(this.token);
      
      if (isSuccess) {
        this.signInSuperAcesso(this.token);
      } 
      this.router.navigate(['/']);
    }
  }

  signInSuperAcesso(tokenAcesso: string) {
    if (this.model.invalid || this.isLoading) return;

    this.isLoading = true;

    this.authService.signInSuperAcesso(tokenAcesso).subscribe({
      next: (response: UsuarioLoginResponse) => {
        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.usuarioLoginResponse = response;
        this.authService.updateToken(response);
        this.isLoading = false;
        this.router.navigate(['/home']);
      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  async validaTokenSuperAcesso(tokenAcesso: string): Promise<boolean> {
      try {
          const response = await firstValueFrom(this.superAcessoService.ValidaSuperAcesso(tokenAcesso));

          if (response.isError) {
              this.alertService.show("Erro", response.errorDescription, AlertType.error);
              this.isLoading = false;
              return false; 
          }

          this.isLoading = false;
          return true;
      } catch (error) { 
          this.alertService.show("Erro inesperado", "", AlertType.error);
          this.isLoading = false;
          return false; 
      }
  }


  singin() {
    if (this.model.invalid || this.isLoading) return;

    this.isLoading = true;
    this.mostrarListaEmpresa = false;

    let idEmpresa: number | null = this.model.get('idEmpresa')?.value ? Number(this.model.get('idEmpresa')?.value) : null;
    let email: string = this.model.get('email')?.value;
    let senha: string = this.model.get('senha')?.value;

    this.authService.signIn(email, senha, this.ip, idEmpresa).subscribe({
      next: (response: UsuarioLoginResponse) => {
        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.usuarioLoginResponse = response;

        if (response.primeiroAcesso) {
          this.abrirModalNovaSenha(email);
          this.isLoading = false;
          return;
        }

        if (response.escolherEmpresa) {
          this.mostrarListaEmpresa = true;
          this.isLoading = false;
          return;
        }

        this.authService.updateToken(response);
        this.isLoading = false;
        this.router.navigate(['/home']);
      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  abrirModalNovaSenha(email: string) {
    const dialogRef = this.dialog.open(ModalPrimeiroAcessoComponent, {
      data: {
        email: email
      },
      panelClass: 'dialog-add-item'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.model.get("senha")?.setValue("");
    });
  }

  getIP() {
    this.utilService.getIP().subscribe({
      next: (response) => {
        if (response && response.ip) {
          this.ip = response.ip;
        }
      }
    });
  }

  redirectRevoceryPassword() {
    this.router.navigate(['/auth/senha-recuperar']);
  }
}
