<div  mat-dialog-title>
    <h2 class="title-default">Primeiro acesso</h2> 
</div>

<form class="form" [formGroup]="model"  (ngSubmit)="post()">
    <mat-dialog-content>
        <div class="search-client">
            <p>Para sua segurança, você precisa alterar sua senha temporaria para poder continuar.</p>
        
            <mat-form-field appearance="outline">
                <mat-label>Senha Antiga</mat-label>
                <input matInput [type]="hideSenhaAntiga() ? 'password' : 'text'" formControlName="senhaAntiga" >
                <button
                    type="button"
                    mat-icon-button
                    matSuffix
                    (click)="clickEventSenhaAntiga($event)"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hideSenhaAntiga()"
                    >
                    <mat-icon>{{hideSenhaAntiga() ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                @if (model.get('senhaAntiga')?.invalid) {
                    <mat-error>Informe a sua senha antiga</mat-error>
                }
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Nova Antiga</mat-label>
                <input matInput [type]="hideSenha1() ? 'password' : 'text'" formControlName="senha1" >
                <button
                    type="button"
                    mat-icon-button
                    matSuffix
                    (click)="clickEventSenha1($event)"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hideSenha1()"
                    >
                    <mat-icon>{{hideSenha1() ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                @if (model.get('senha1')?.invalid) {
                    <mat-error>Informe a nova senha</mat-error>
                }
            </mat-form-field>


            <mat-form-field appearance="outline">
                <mat-label>Nova Antiga</mat-label>
                <input matInput [type]="hideSenha2() ? 'password' : 'text'" formControlName="senha2" >
                <button
                    type="button"
                    mat-icon-button
                    matSuffix
                    (click)="clickEventSenha2($event)"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hideSenha2()"
                    >
                    <mat-icon>{{hideSenha2() ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                @if (model.get('senha2')?.invalid) {
                    <mat-error>Informe a nova senha novamente</mat-error>
                }
            </mat-form-field>        
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button mat-flat-button [mat-dialog-close]="" type="button">Fechar</button>  
        <button mat-flat-button class="btn-primary" type="submit">Alterar minha senha</button>  
    </mat-dialog-actions>
</form>
<!-- <mat-dialog-actions>
  <button mat-button >No Thanks</button>
  <button mat-button [mat-dialog-close]="" cdkFocusInitial>Ok</button>
</mat-dialog-actions> -->