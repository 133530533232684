import { Component, OnInit, signal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import { Router } from '@angular/router';
import { UtilService } from '../../../shared/services/util.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatIconModule} from '@angular/material/icon';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { AuthService } from '../../../shared/services/API/usuario/auth.service';
import { UsuarioLoginResponse } from '../../../shared/services/responses/usuario/usuario-login.response';
import {MatSelectModule} from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { ModalPrimeiroAcessoComponent } from './modal-primeiro-acesso/modal-primeiro-acesso.component';

@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [    
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatSelectModule
  ],
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.css'
})
export class AuthComponent implements OnInit {
  
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService,
    public dialog: MatDialog,
  ){}

  public model: FormGroup;
  public isLoading:boolean = false;
  private ip: string = '192.168.0.2';
  public mostrarListaEmpresa:boolean = false;
  public usuarioLoginResponse:UsuarioLoginResponse;
 
  hide = signal(true);
  clickEvent(event: MouseEvent) {
    this.hide.set(!this.hide());
    event.stopPropagation();
  }
   
  ngOnInit(): void {
    this.model = this.formBuilder.group({
      email: ['', [Validators.required]],
      senha: ['', [Validators.required]],
      idEmpresa: [''], 
    });

    setTimeout(() => { this.getIP(); }, 2000);
  }

  singin() {
    if (this.model.invalid  || this.isLoading)
      return;

    this.isLoading = true;
    this.mostrarListaEmpresa = false

    let idEmpresa:number|null = this.model.get('idEmpresa')?.value? Number(this.model.get('idEmpresa')?.value): null; 
    let email:string = this.model.get('email')?.value;
    let senha:string = this.model.get('senha')?.value;

    this.authService.signIn(email, senha, this.ip, idEmpresa).subscribe({
      next: (response:UsuarioLoginResponse) => {
        if (response.isError) {
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.usuarioLoginResponse = response;
  
        if (response.primeiroAcesso) {
          this.abrirModalNovaSenha(email);
          this.isLoading = false;
          return;
        }

        if (response.escolherEmpresa) {
          this.mostrarListaEmpresa = true
          this.isLoading = false;
          return;
        }
          
        this.authService.updateToken(response);
        this.isLoading = false;

        this.router.navigate(['/home'])
        
      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  abrirModalNovaSenha(email:string){
    const dialogRef = this.dialog.open(ModalPrimeiroAcessoComponent, {      
      data: {
        email: email
      },
      panelClass: 'dialog-add-item'
    });

    dialogRef.afterClosed().subscribe(result => {
      this.model.get("senha")?.setValue("");
    });
  }

  getIP() {
    this.utilService.getIP().subscribe({
      next: (response) => {
        if (response && response.ip) {
          this.ip = response.ip;
        }
      }
    });
  }

  redirectRevoceryPassword(){
    this.router.navigate(['/auth/senha-recuperar'])
  }


}
