@if(showActionNav){
    <div class="container-item">

        @if(_usuarioLoginResponse.listaModulos){
            @for (modulo of _usuarioLoginResponse.listaModulos; track modulo) {
                <a class="btn-nav">
                    <div class="icon" routerLink="{{modulo.uri}}" [ngClass]="{'icon-active': modulo.idModulo == moduloEnum}">
                        <span class="material-symbols-outlined">{{modulo.googleIconClass}}</span>
                    </div>
                    <label>{{modulo.nome}}</label>
                </a>
              }
        }
    </div>
    <div class="container-out" (click)="clickContainerOut()"></div>
}
