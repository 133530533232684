import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from '../../common-service';
import { environment } from '../../../../../environments/environment';
import { ListaTimezoneResponse } from '../../responses/usuario/lista-timezone.response';


@Injectable({
  providedIn: 'root'
})
export class TimezoneService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public get(): Observable<ListaTimezoneResponse> {    
      return this.httpClient.get<ListaTimezoneResponse>(environment.urlApiUsuario + `Timezone`, this.addHeaderToken())
      .pipe(
      catchError(this.handleError)
      )
  }  
}
 