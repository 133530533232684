import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModuloEnum } from '../../../enum/modulo.enum';
import { Funcionalidade } from '../../../services/models/usuario/funcionalidade.model';
import { AuthService } from '../../../services/API/usuario/auth.service';
import { UsuarioLoginResponse } from '../../../services/responses/usuario/usuario-login.response';
import { RouterModule } from '@angular/router';
import { FuncionalidadeEnum } from '../../../enum/funcionalidade.enum';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-funcionalidades',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule
  ],
  templateUrl: './funcionalidades.component.html',
  styleUrl: './funcionalidades.component.css'
})
export class FuncionalidadesComponent implements OnInit{

  constructor(public authService:AuthService){
  }

  @Input() showActionFuncionalidade:boolean;
  @Output() showActionFuncionalidadeFalse =  new EventEmitter<number>()

  @Input() moduloEnum:ModuloEnum;
  @Input() funcionalidadeEnum:FuncionalidadeEnum;

  public _listaFuncionalidade:Funcionalidade[];
  
  ngOnInit(): void {
    let usuarioLoginResponse:UsuarioLoginResponse = this.authService.getUserInfos();
    this._listaFuncionalidade = usuarioLoginResponse.listaFuncionalidades.filter(c=> c.idModulo == this.moduloEnum);

    
  }

  clickContainerOut(){
    this.showActionFuncionalidadeFalse.emit();
  }
}
