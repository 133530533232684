<app-action-user [showActionUser]="showActionUser" (showActionUserFalse)="clickShowActionUser(false)"></app-action-user>
<app-action-nav [showActionNav]="showActionNav" [moduloEnum]="moduloEnum" (showActionNavFalse)="clickShowActionNav(false)"></app-action-nav>
<app-funcionalidades [showActionFuncionalidade]="showActionFuncionalidade" [moduloEnum]="moduloEnum" [funcionalidadeEnum]=funcionalidadeEnum (showActionFuncionalidadeFalse)="clickShowFuncionalidade(false)"></app-funcionalidades>

<header>
    <div class="container">
        <div class="action-funcionalidade">
            <a (click)="clickShowFuncionalidade(null)">
                <span class="material-symbols-outlined">menu</span>
                {{nomeModulo}}
            </a>
        </div>
        <div class="logo"> 
            <a routerLink="/factory/home"> 
                <img class="logo-auth" src="assets/images/logo-ats.png">
            </a>
        </div>
        <div class="action">
            @if (areThereNotifications) {
                <a (click)="clickShowActionInvites(null)"><span matBadge="!" matBadgeColor="accent" matBadgeSize="small" matBadgeOverlap="false" class="material-symbols-outlined">notifications</span></a>
            }
            @else {
                <a (click)="clickShowActionInvites(null)"><span class="material-symbols-outlined">notifications</span></a>
            }

            <a (click)="clickShowActionNav(null)"><span class="material-symbols-outlined">apps</span></a>
            <a (click)="clickShowActionUser(null)"><span class="material-symbols-outlined">account_circle</span></a>
        </div>
    </div>
</header>
 
<div class="space-header"></div>