import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from '../../common-service';
import { environment } from '../../../../../environments/environment';
import { ReturnStruct } from '../../structs/return.struct';
import { DatabaseRequest } from '../../requests/interface-acesso-bd-resulth-local/database.request';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public post(testarConexaoBDRequest: DatabaseRequest): Observable<ReturnStruct> {  
    return this.httpClient.post<ReturnStruct>(environment.urlApiInterfaceAcessoBDResulthLocal + `connection/test`, testarConexaoBDRequest,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }  
}
 