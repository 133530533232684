import { HttpClient } from '@angular/common/http';
import { afterNextRender, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../common-service';
import { environment } from '../../../../../environments/environment';
import { UsuarioLoginResponse } from '../../responses/usuario/usuario-login.response';
import { ReturnStruct } from '../../structs/return.struct';
import { PrimeiroAcessoRequest } from '../../requests/usuario/primeiro-acesso.request';
import { AlterarSenhaRequest } from '../../requests/usuario/alterar-aenha.request';
import { RecuperarSenhaResponse } from '../../responses/usuario/recuperar-senha.response';
import { ResetarSenhaRequest } from '../../requests/usuario/resetar-senha.request';

@Injectable({
  providedIn: 'root'
})
export class AlterarSenhaService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  public post(alterarSenhaRequest:AlterarSenhaRequest): Observable<RecuperarSenhaResponse> {
    return this.httpClient.post<RecuperarSenhaResponse>(environment.urlApiUsuario + "AlterarSenha", alterarSenhaRequest)
    .pipe(
      catchError(this.handleError)
    )
  }

  public get(token:string): Observable<ReturnStruct> {
    return this.httpClient.get<ReturnStruct>(environment.urlApiUsuario + "AlterarSenha/token/"+token)
    .pipe(
      catchError(this.handleError)
    )
  }

  public put(resetarSenhaRequest:ResetarSenhaRequest): Observable<RecuperarSenhaResponse> {
    return this.httpClient.put<RecuperarSenhaResponse>(environment.urlApiUsuario + "AlterarSenha", resetarSenhaRequest)
    .pipe(
      catchError(this.handleError)
    )
  }
}
 