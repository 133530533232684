import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AlertService, AlertType } from '../../../../shared/services/alert.service';
import { PrimeiroAcessoService } from '../../../../shared/services/apis/usuario/primeiro-acesso.service';
import { PrimeiroAcessoRequest } from '../../../../shared/services/requests/usuario/primeiro-acesso.request';
import { ReturnStruct } from '../../../../shared/services/structs/return.struct';

@Component({
    selector: 'app-modal-primeiro-acesso',
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatButtonModule,
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        MatDialogClose,
        MatProgressSpinnerModule,
        MatIconModule,
        MatProgressSpinnerModule,
        CommonModule
    ],
    templateUrl: './modal-primeiro-acesso.component.html',
    styleUrl: './modal-primeiro-acesso.component.css'
})
export class ModalPrimeiroAcessoComponent implements OnInit{

  constructor(
    private primeiroAcessoService:PrimeiroAcessoService,
    private formBuilder: FormBuilder, 
    private alertService: AlertService,
    public dialogRef: MatDialogRef<ModalPrimeiroAcessoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ){
    this.email = data.email
  }
  
  public model: FormGroup;
  public _isLoading = false;
  private email:string;

  hideSenhaAntiga = signal(true);
  clickEventSenhaAntiga(event: MouseEvent) {
    this.hideSenhaAntiga.set(!this.hideSenhaAntiga());
    event.stopPropagation();
  }

  hideSenha1 = signal(true);
  clickEventSenha1(event: MouseEvent) {
    this.hideSenha1.set(!this.hideSenha1());
    event.stopPropagation();
  }

  hideSenha2 = signal(true);
  clickEventSenha2(event: MouseEvent) {
    this.hideSenha2.set(!this.hideSenha2());
    event.stopPropagation();
  }
  

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      senhaAntiga: [''],
      senha1: [''],
      senha2: ['']
    });
  }
  
  post(){
    this._isLoading = true;

    let primeiroAcessoRequest:PrimeiroAcessoRequest = new PrimeiroAcessoRequest();

    primeiroAcessoRequest.email = this.email;
    primeiroAcessoRequest.senhaAntiga = this.model.get("senhaAntiga")?.value;
    primeiroAcessoRequest.senha1 = this.model.get("senha1")?.value;
    primeiroAcessoRequest.senha2 = this.model.get("senha2")?.value;

    this.primeiroAcessoService.put(primeiroAcessoRequest).subscribe({
      next: (returnStruct:ReturnStruct) => {
        if (returnStruct.isError) {
          this.alertService.show("Erro", returnStruct.errorDescription, AlertType.error);
          this._isLoading = false;
          return;
        }
        
        this.dialogRef.close();
        this.alertService.show("Sucesso", "Sua senha foi atualizada com sucesso.", AlertType.success);
               
      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this._isLoading = false;
      }
    });
  }
}
