import { Component } from '@angular/core';
import { ModuloEnum } from '../../../shared/enums/modulo.enum';
import { FuncionalidadeEnum } from '../../../shared/enums/funcionalidade.enum';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { MenuComponent } from "../../../shared/components/menu/menu.component";
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { Router } from '@angular/router';
import { ConfirmModalComponent } from '../../../shared/components/confirm-modal/confirm-modal.component';
import { UsuarioResponse } from '../../../shared/services/responses/usuario/usuario.response';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { Masks, MaskService } from '../../../shared/services/mask.service';
import { AuthService } from '../../../shared/services/apis/usuario/auth.service';
import { UsuarioLoginResponse } from '../../../shared/services/responses/usuario/usuario-login.response';
import { Funcionalidade } from '../../../shared/services/models/usuario/funcionalidade.model';
import { PermissaoEnum } from '../../../shared/enums/permissao.enum';
import { TimezoneService } from '../../../shared/services/apis/usuario/timezone.service';
import { ListaTimezoneResponse } from '../../../shared/services/responses/usuario/lista-timezone.response';
import { EmpresaService } from '../../../shared/services/apis/usuario/empresa.service';
import { EmpresaResponse } from '../../../shared/services/responses/usuario/empresa.response';
import { EmpresaRequest } from '../../../shared/services/requests/usuario/empresa.request';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { TimezoneStruct } from '../../../shared/services/structs/usuario/timezone.struct';
import { map, Observable, startWith } from 'rxjs';
import { DatabaseService } from '../../../shared/services/apis/interface-acesso-bd-resulth-local/database.service';
import { DatabaseRequest } from '../../../shared/services/requests/interface-acesso-bd-resulth-local/database.request';

@Component({
    selector: 'app-usuario-cadastro',
    imports: [
      MenuComponent,
      FormsModule,
      MatInputModule,
      MatFormFieldModule,
      ReactiveFormsModule,
      MatButtonModule,
      MatIconModule,
      MatProgressSpinnerModule,
      CommonModule,
      MatTableModule,
      MatSelectModule,
      MatTooltipModule,
      MatSlideToggleModule,
      MatAutocompleteModule,
      NgxMaskDirective,
    ],
    providers: [
      provideNgxMask()
    ],
    templateUrl: './configurar-empresa.component.html',
    styleUrl: './configurar-empresa.component.css'
})
export class ConfigurarEmpresaComponent {
  public moduloEnum:ModuloEnum;
  public funcionalidadeEnum:FuncionalidadeEnum
  public timezoneFiltrado: Observable<TimezoneStruct[]>;

  constructor(private formBuilder: FormBuilder,
    private timezoneService: TimezoneService,
    private empresaService: EmpresaService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private router: Router,
    private maskService:MaskService,
    private authService: AuthService, 
    private dabaseService: DatabaseService,
  ){
    this.moduloEnum = ModuloEnum.Master
    this.funcionalidadeEnum = FuncionalidadeEnum.Master_Empresa
  }
  
  public model: FormGroup;
  public _isLoadingButtonSalvar: boolean = false;
  public _isLoadingButtonTestarConexao: boolean = false;
  public _empresaResponse: EmpresaResponse;
  public _usuarioResponse: UsuarioResponse;
  public _timezoneLookupResponse:ListaTimezoneResponse;
  public buttonDesativado: boolean = false;
  public masks: Masks;
  public userLoginResponse: UsuarioLoginResponse;
  public listaTimezoneFiltrado: Observable<TimezoneStruct[]>;
  public senhaBDPadrao: string = "************";

  async ngOnInit(): Promise<void> {
    this.masks = this.maskService.getMasks();

    this.model = this.formBuilder.group({
      nomeEmpresa: new FormControl('', Validators.required),
      cnpj: new FormControl('', Validators.required),
      listaTimezone: new FormControl('', Validators.required), 
      urlApiAcessoBDResulthLocal: new FormControl('', Validators.required),
      caminhoBDResulthLocal: new FormControl('', Validators.required),
      usuarioBDResulthLocal: new FormControl('', Validators.required),
      senhaBDResulthLocal: new FormControl({ value: '', disabled: true }, Validators.required),
      codFilialBDResulthLocal: new FormControl('', Validators.required)
    });

    this.validarPermissoes();
    await this.lookupTimezone();
    await this.popularDadosEmpresa();
  }

  validarPermissoes(){
    this.userLoginResponse = this.authService.getUserInfos();    

    if (this.userLoginResponse == null) {      
      this.router.navigate(['/']);
    }

    let listaFuncinalidade: Funcionalidade[] = this.userLoginResponse
      .listaFuncionalidades.filter(funcionalidade => {
        return funcionalidade.idFuncionalidade == FuncionalidadeEnum.Master_Empresa;
      }) 

    if (listaFuncinalidade.length == 0) {
      this.router.navigate(['/master']);
    }   

    if (!this.userLoginResponse.listaPermissoes.includes(PermissaoEnum.Master_ConfigurarEmpresa_Alterar)){
      return this.disableFields();
    }
  }

  private disableFields(){
    this.model.get('nomeEmpresa')?.disable();
    this.model.get('cnpj')?.disable();
    this.model.get('listaTimezone')?.disable();
    this.model.get('urlApiAcessoBDResulthLocal')?.disable();
    this.model.get('caminhoBDResulthLocal')?.disable();
    this.model.get('usuarioBDResulthLocal')?.disable();
    this.model.get('senhaBDResulthLocal')?.disable();
    this.model.get('codFilialBDResulthLocal')?.disable();
    this.buttonDesativado = true;
  }
  
  async lookupTimezone(){
    return new Promise<void>((resolve, reject) => {
      this.timezoneService.get().subscribe({
        next: (listaTimezoneResponse:ListaTimezoneResponse) => {
          if (listaTimezoneResponse.isError) {
            this.alertService.show("Erro", listaTimezoneResponse.errorDescription, AlertType.error);
            return;
          }          

          this._timezoneLookupResponse = listaTimezoneResponse;                     
          const listaTimezoneControl = this.model.get('listaTimezone');
          if (listaTimezoneControl instanceof FormControl) {
            this.listaTimezoneFiltrado = listaTimezoneControl.valueChanges.pipe(
              startWith(''),
              map(value => this.filtraTimezone(value))
            );      
          }  
          resolve();               
        },
        error: (error) => {
          this.alertService.show("Erro inesperado", error, AlertType.error);
          reject();
        }
      });
    })
  }

  private filtraTimezone(pesquisa: string): TimezoneStruct[] {
    if (!this._timezoneLookupResponse || !this._timezoneLookupResponse.listaTimezone) {
      return [];
    }

    if (!pesquisa.trim()) {
      return this._timezoneLookupResponse.listaTimezone;
    }

    const valorFiltrado = pesquisa.toLowerCase();
    return this._timezoneLookupResponse.listaTimezone.filter(timezone =>
      timezone.nome.toLowerCase().includes(valorFiltrado)
    );
  }

  async popularDadosEmpresa(){
    return new Promise<void>((resolve, reject) => {
      this.empresaService.ObterPorId(this.userLoginResponse.idEmpresa).subscribe({
        next: (empresaResponse:EmpresaResponse) => {
          if (empresaResponse.isError) {
            this.alertService.show("Erro", empresaResponse.errorDescription, AlertType.error);
            return;
          }                  

          this.model.get('nomeEmpresa')?.setValue(empresaResponse.empresa.nomeEmpresa);
          this.model.get('cnpj')?.setValue(empresaResponse.empresa.cnpj);
          this.model.get('urlApiAcessoBDResulthLocal')?.setValue(empresaResponse.empresa.urlApiAcessoBDResulthLocal);
          this.model.get('listaTimezone')?.setValue(empresaResponse.empresa.timezoneNomeUtc);
          this.model.get('caminhoBDResulthLocal')?.setValue(empresaResponse.empresa.caminhoBDResulthLocal);
          this.model.get('usuarioBDResulthLocal')?.setValue(empresaResponse.empresa.usuarioBDResulthLocal);
          this.model.get('senhaBDResulthLocal')?.setValue(this.senhaBDPadrao);
          this.model.get('codFilialBDResulthLocal')?.setValue(empresaResponse.empresa.codFilialBDResulthLocal);
          this._empresaResponse = empresaResponse;
          resolve();  
        },
        error: (error) => {
          this.alertService.show("Erro inesperado", error, AlertType.error);
          reject();
        }
      });
    })
  }
  
  openModal() {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: "Alterar a senha",
        innerHtml: `<p>Deseja realizar a troca da senha para a conexão com o banco?</p>`,
        buttonConfirm: "Alterar",
        buttonClose: "Fechar"
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.confirm) 
          this.editarSenha();
      }
    });
  }

  public editarSenha() {    
    const senhaBancoControl = this.model.get('senhaBDResulthLocal');
    if (senhaBancoControl?.disabled) {
      senhaBancoControl.enable();
      senhaBancoControl.setValue('');
    }
  }

  validaCamposTestarConexao(databaseRequest: DatabaseRequest): boolean {
    const camposInvalidos = [
      { condicao: !databaseRequest.urlApiAcessoBDResulthLocal, mensagem: "A URL da API informada é inválida" },
      { condicao: !databaseRequest.caminhoBD, mensagem: "O caminho do banco informado é inválido" },
      { condicao: !databaseRequest.usuarioBD, mensagem: "O usuário do banco informado é inválido" },
    ];

    for (const campo of camposInvalidos) {
      if (campo.condicao) {
        this.alertService.show("Erro", campo.mensagem, AlertType.error);
        return false;
    }}

    return true;
  }

  async testarConexao() {
    let databaseRequest:DatabaseRequest = new DatabaseRequest();

    databaseRequest.urlApiAcessoBDResulthLocal = this.model.get("urlApiAcessoBDResulthLocal")?.value;
    databaseRequest.caminhoBD = this.model.get("caminhoBDResulthLocal")?.value;
    databaseRequest.usuarioBD = this.model.get("usuarioBDResulthLocal")?.value;    

    if (this.model.get("senhaBDResulthLocal")?.value != "" &&
        this.model.get("senhaBDResulthLocal")?.value != this.senhaBDPadrao) {
          databaseRequest.senhaBD = this.model.get("senhaBDResulthLocal")?.value;
    } else {
      databaseRequest.senhaBD = this._empresaResponse.empresa.senhaBDResulthLocal;
    }

    if (!this.validaCamposTestarConexao(databaseRequest)) {
      return;
    }   

    this._isLoadingButtonTestarConexao = true;
    this.buttonDesativado = true;  

    await this.conectarBD(databaseRequest);

    this.buttonDesativado = false;
    this._isLoadingButtonTestarConexao = false;
  }

  async conectarBD(databaseRequest: DatabaseRequest) {

    this.dabaseService.post(databaseRequest).subscribe({
      next: (returnStruct:ReturnStruct) => {
        this._isLoadingButtonSalvar = false;
        this.buttonDesativado = false;
        
        if (returnStruct.isError) {
          this.alertService.show("Erro", returnStruct.errorDescription, AlertType.error);
          return;
        }

        this.alertService.show("Sucesso", "Conexão ao banco realizada com sucesso.", AlertType.success);
      },
      error: (error) => {
        this._isLoadingButtonTestarConexao = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  submit(){
    if (this._isLoadingButtonSalvar || this.model.invalid) {
      return;
    }

    let empresaRequest:EmpresaRequest = new EmpresaRequest();   

    empresaRequest.nomeEmpresa = this.model.get("nomeEmpresa")?.value;
    empresaRequest.cnpj = this.model.get("cnpj")?.value;    
    empresaRequest.timezoneNomeUtc = this.model.get("listaTimezone")?.value;
    empresaRequest.timezone = this.retornaNumeroTimezone(this.model.get("listaTimezone")?.value);
    empresaRequest.urlApiAcessoBDResulthLocal = this.model.get("urlApiAcessoBDResulthLocal")?.value;
    empresaRequest.caminhoBDResulthLocal = this.model.get("caminhoBDResulthLocal")?.value;
    empresaRequest.usuarioBDResulthLocal = this.model.get("usuarioBDResulthLocal")?.value;
    empresaRequest.codFilialBDResulthLocal = this.model.get("codFilialBDResulthLocal")?.value;

    if (this.model.get("senhaBDResulthLocal")?.value != "" &&
        this.model.get("senhaBDResulthLocal")?.value != this.senhaBDPadrao) {
      empresaRequest.senhaBDResulthLocal = this.model.get("senhaBDResulthLocal")?.value;
    }

    if(this.model.get("listaTimezone")?.value == null || this.model.get("listaTimezone")?.value == "") {
      this.alertService.show("Erro", "Selecione um timezone", AlertType.error);
      return;
    }

    if(!this.validarCNPJ(this.model.get("cnpj")?.value)) {
      this.alertService.show("Erro", "O CNPJ informado é inválido", AlertType.error);
      return;
    }

    this.alterar(empresaRequest);
  }

  private alterar(empresaRequest:EmpresaRequest):void{
    this._isLoadingButtonSalvar = true;
    this.buttonDesativado = true;    

    this.empresaService.Alterar(this.userLoginResponse.idEmpresa, empresaRequest).subscribe({
      next: (returnStruct:ReturnStruct) => {
        this._isLoadingButtonSalvar = false;
        this.buttonDesativado = false; 
        if (returnStruct.isError) {
          this.alertService.show("Erro", returnStruct.errorDescription, AlertType.error);
          return;
        }

        this.alertService.show("Sucesso", "Empresa alterada com sucesso.", AlertType.success);
      },
      error: (error) => {
        this._isLoadingButtonSalvar = false;
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  private retornaNumeroTimezone(timezoneUtc: string): number {
    let timezone: TimezoneStruct | undefined = this._timezoneLookupResponse.listaTimezone.find(timezone => timezone.nome == timezoneUtc); 
    
    if (timezone !== undefined) {
      return timezone.utcOffset;
    }
    return 0;
  }

  private validarCNPJ(cnpj: string): boolean {
    cnpj = cnpj.replace(/[\.\/-]/g, ''); // Remove caracteres especiais
  
    if (cnpj.length !== 14 || /^(\d)\1+$/.test(cnpj)) {
      return false; // Verifica se o CNPJ tem 14 dígitos e não é uma sequência repetida
    }
  
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
  
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) pos = 9;
    }
  
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(0))) {
      return false;
    }
  
    tamanho++;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) pos = 9;
    }
  
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    return resultado === parseInt(digitos.charAt(1));
  }

  somenteNumeros(event: any): void {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/\D/g, '');
    event.target.value = inputValue;
  }

  formatandoCampo() {
    var campoFormatado = this.model.get("codFilialBDResulthLocal")?.value.length == 1 ?
      "0" + this.model.get("codFilialBDResulthLocal")?.value : 
      this.model.get("codFilialBDResulthLocal")?.value;

    this.model.get("codFilialBDResulthLocal")?.setValue(campoFormatado);
  }
}
