import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UsuarioLoginResponse } from '../../../services/responses/usuario/usuario-login.response';
import { AuthService } from '../../../services/apis/usuario/auth.service';
import { ModuloEnum } from '../../../enums/modulo.enum';
import { Modulo } from '../../../services/models/usuario/modulo.model';
import { Funcionalidade } from '../../../services/models/usuario/funcionalidade.model';


@Component({
    selector: 'app-action-nav',
    imports: [
        CommonModule,
        RouterModule
    ],
    templateUrl: './action-nav.component.html',
    styleUrl: './action-nav.component.css'
})
export class ActionNavComponent implements OnInit{
  @Input() showActionNav:boolean;
  @Input() moduloEnum:ModuloEnum;
  @Output() showActionNavFalse =  new EventEmitter<number>()
  
  public _usuarioLoginResponse:UsuarioLoginResponse;

  
  constructor(public authService:AuthService){
  }

  ngOnInit(): void {
    this._usuarioLoginResponse = this.authService.getUserInfos()
  }

  
  clickContainerOut(){
    this.showActionNavFalse.emit();
  }
}
