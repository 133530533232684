<div class="container container-auth"> 
    <div class="vertical-aling-out">
        <div class="vertical-aling-inner">
            <form class="form" [formGroup]="model"  (ngSubmit)="singin()">
                <img class="logo-auth" src="assets/images/logo-ats.png">
                <mat-form-field appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" type="email">
                    @if (model.get('email')?.invalid) {
                        <mat-error>Informe o seu e-mail cadastrado</mat-error>
                    }
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Senha</mat-label>
                    <input matInput [type]="hide() ? 'password' : 'text'" formControlName="senha" >
                    <button
                        type="button"
                        mat-icon-button
                        matSuffix
                        (click)="clickEvent($event)"
                        [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hide()"
                        >
                        <mat-icon>{{hide() ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                    @if (model.get('senha')?.invalid) {
                        <mat-error>Informe a sua senha</mat-error>
                    }
                </mat-form-field>

                @if(mostrarListaEmpresa && usuarioLoginResponse.listaEmpresa){
                    <mat-form-field appearance="outline">
                        <mat-label>Empresa</mat-label> 
                        <mat-select  formControlName="idEmpresa">
                            @for (empresa of usuarioLoginResponse.listaEmpresa; track empresa) {
                              <mat-option [value]="empresa.idEmpresa">{{empresa.nomeEmpresa}}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                }


                <button type="submit" mat-flat-button class="btn-primary button-big button-login">
                    @if (isLoading == true) {
                        <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                    }
                    @else{
                        Entrar
                    }
                </button>
                <button type="button" mat-button class="button-big button-login" (click)="redirectRevoceryPassword()">Esqueceu a senha?</button>
            </form>
        </div>
    </div>
  </div>