<div class="container container-auth"> 
    <div class="vertical-aling-out">
        <div class="vertical-aling-inner">
            <img class="logo-auth" src="assets/images/logo-ats.png">
            @if(_verifyTokenReturnStruct != undefined){
                @if(_verifyTokenReturnStruct.isError == false){
                    <form class="form" [formGroup]="model"  (ngSubmit)="post()" class="text-center">
                        <h1>Redefinir senha</h1>
                        <p>
                            Insira sua nova senha.
                        </p>
                        <mat-form-field appearance="outline">
                            <mat-label>Nova Antiga</mat-label>
                            <input matInput [type]="hideSenha1() ? 'password' : 'text'" formControlName="senha1" >
                            <button
                                type="button"
                                mat-icon-button
                                matSuffix
                                (click)="clickEventSenha1($event)"
                                [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hideSenha1()"
                                >
                                <mat-icon>{{hideSenha1() ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            @if (model.get('senha1')?.invalid) {
                                <mat-error>Informe a nova senha</mat-error>
                            }
                        </mat-form-field>
            
            
                        <mat-form-field appearance="outline">
                            <mat-label>Nova Antiga</mat-label>
                            <input matInput [type]="hideSenha2() ? 'password' : 'text'" formControlName="senha2" >
                            <button
                                type="button"
                                mat-icon-button
                                matSuffix
                                (click)="clickEventSenha2($event)"
                                [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hideSenha2()"
                                >
                                <mat-icon>{{hideSenha2() ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            @if (model.get('senha2')?.invalid) {
                                <mat-error>Informe a nova senha novamente</mat-error>
                            }
                        </mat-form-field>  
    
                        <button type="submit" mat-flat-button class="btn-primary button-big button-login">
                            @if (isLoading == true) {
                                <mat-spinner [diameter]="30" class="mat-spinner-btn"></mat-spinner>
                            }
                            @else{
                                Redefinir Senha
                            }
                        </button>
                        <button type="button" mat-button class="button-big button-login" (click)="redirectLogin()">Voltar</button>
                    </form>
                } 
                @else {
                    <div class="text-center">
                        <h1>Link invalido</h1>
                        <!-- <p>{{_verifyTokenReturnStruct.errorDescription}}</p> -->
                        <button type="button" mat-button class="button-big button-login" (click)="redirectLogin()">Voltar</button>
                    </div>
                    
                }
            }
           @else{
                <div class="text-center">
                    <mat-spinner [diameter]="60"></mat-spinner>
                </div>
           }
        </div>
    </div>
  </div>