@if(showActionFuncionalidade){
    <div class="container-item">

        @if(_listaFuncionalidade){
            @for (funionalidade of _listaFuncionalidade; track funionalidade) {
                <ul>
                    <li>
                        <a routerLink="{{funionalidade.uri}}" [ngClass]="{'funcionalidade-active': funionalidade.idFuncionalidade == funcionalidadeEnum}">
                            <span class="material-symbols-outlined">{{funionalidade.googleIconClass}}</span>
                            {{funionalidade.nome}}
                        </a>
                    </li>
                </ul>
              }
        }
    </div>
    <div class="container-out" (click)="clickContainerOut()"></div>
}
