import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { Router } from '@angular/router';
import { AlterarSenhaRequest } from '../../../shared/services/requests/usuario/alterar-aenha.request';
import { AlterarSenhaService } from '../../../shared/services/apis/usuario/alterar-senha.service';
import { RecuperarSenhaResponse } from '../../../shared/services/responses/usuario/recuperar-senha.response';

@Component({
    selector: 'app-senha-recuperar',
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatIconModule
    ],
    templateUrl: './senha-recuperar.component.html',
    styleUrl: './senha-recuperar.component.css'
})
export class SenhaRecuperarComponent implements OnInit {
  constructor( 
    private formBuilder: FormBuilder,
    private router: Router,
    private alertService: AlertService, 
    private alterarSenhaService:AlterarSenhaService){

  }
  public model: FormGroup;
  public isLoading:boolean = false;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  redirectLogin(){
    this.router.navigate(['/'])
  }

  post(){
    if (this.model.invalid  || this.isLoading)
      return;

    let alterarSenhaRequest:AlterarSenhaRequest = new AlterarSenhaRequest();
    alterarSenhaRequest.email = this.model.get('email')?.value;

    this.isLoading = true;

    this.alterarSenhaService.post(alterarSenhaRequest).subscribe({
      next: (response:RecuperarSenhaResponse) => {
        this.isLoading = false;

        if (response.isError) {
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          return;
        }
        
        this.alertService.show("Sucesso", "O link de redefinição de senha foi enviado para o email " + response.emailEscondido, AlertType.success);
        this.redirectLogin();
      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
      }
    });
  }
}
