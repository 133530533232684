import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../common-service';
import { environment } from '../../../../../environments/environment';
import { UsuarioLoginResponse } from '../../responses/usuario/usuario-login.response';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient, 
    @Inject(DOCUMENT) private _doc: Document) {
    super();
  }

  public signIn(email: string, senha: string, ip: string, idEmpresa:number|null): Observable<UsuarioLoginResponse> {

    let userAuthRequest ={
      email: email,
      senha: senha,
      ip: ip,
      idEmpresa: idEmpresa
    };

    return this.httpClient.post<UsuarioLoginResponse>(environment.urlApiUsuario + "Auth", userAuthRequest)
    .pipe(
      catchError(this.handleError)
    )
  }

  public signInSuperAcesso(tokenSuperAcesso: string): Observable<UsuarioLoginResponse> {
    console.log(tokenSuperAcesso);
    return this.httpClient.post<UsuarioLoginResponse>(
      environment.urlApiUsuario + "auth/token/" + tokenSuperAcesso, {})
    .pipe(
      catchError(this.handleError)
    )
  }

  updateToken(userLoginResponse: UsuarioLoginResponse) {
    
    this._doc.defaultView?.sessionStorage?.setItem("token", JSON.stringify(userLoginResponse));
  }

  getToken() {
    let userLoginResponse: UsuarioLoginResponse = JSON.parse(window?.sessionStorage?.getItem("token")!);
    return userLoginResponse.token; 
  }

  getUserInfos():UsuarioLoginResponse {
    let userLoginResponse: UsuarioLoginResponse = JSON.parse(window?.sessionStorage?.getItem("token")!);
    return userLoginResponse;
  }

  logout() {
    this._doc.defaultView?.sessionStorage?.removeItem("token");
    this.router.navigate(['/'])
  }
  
  isAuthenticated(): boolean {
    return this._doc.defaultView?.sessionStorage?.getItem("token") == null ? false : true;
  }
 
}
 