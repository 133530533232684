import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuComponent } from '../../../shared/components/menu/menu.component';
import { ModuloEnum } from '../../../shared/enums/modulo.enum';
import { FuncionalidadeEnum } from '../../../shared/enums/funcionalidade.enum';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatTableDataSource, MatTableModule} from '@angular/material/table';
import { UsuarioService } from '../../../shared/services/apis/usuario/usuario.service';
import { AlertService, AlertType } from '../../../shared/services/alert.service';
import { ListaUsuarioResponse } from '../../../shared/services/responses/usuario/lista-usuario.response';
import { LoadingBoxComponent } from "../../../shared/components/loading-box/loading-box.component";
import { ConfirmModalComponent } from '../../../shared/components/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ReturnStruct } from '../../../shared/services/structs/return.struct';
import { RouterModule } from '@angular/router';
import { EmptyListComponent } from "../../../shared/components/empty-list/empty-list.component";
import { MatTooltipModule } from '@angular/material/tooltip';
import { UsuarioListaStruct } from '../../../shared/services/structs/usuario/usuario-lista.struct';

@Component({
    selector: 'app-usuario-lista',
    imports: [
        MenuComponent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
        CommonModule,
        MatTableModule,
        LoadingBoxComponent,
        RouterModule,
        EmptyListComponent,
        MatTooltipModule
    ],
    templateUrl: './usuario-lista.component.html',
    styleUrl: './usuario-lista.component.css'
})
export class UsuarioListaComponent implements OnInit{
  public moduloEnum:ModuloEnum;
  public funcionalidadeEnum:FuncionalidadeEnum

  constructor(private formBuilder: FormBuilder,
    private usuarioService:UsuarioService,
    private alertService: AlertService,
    private dialog: MatDialog,
  ){
    this.moduloEnum = ModuloEnum.Master
    this.funcionalidadeEnum = FuncionalidadeEnum.Master_Usuario
  }

  public model: FormGroup;
  public _isLoading:boolean = false;
  public _listaUsuarioResponse:ListaUsuarioResponse|undefined;
  public displayedColumns: string[] = ['idUsuario', 'nomeUsuario', 'email', 'telefone', 'nomeEmpresaCriadora', "action-edit", "action-delete"];
  public _dataSource = new MatTableDataSource<UsuarioListaStruct>();
  public _paginaAtual:number = 0;
  public _ultimaPagina:boolean = false
    
  ngOnInit(): void {
    this.model = this.formBuilder.group({
      nomeUsuario: [''],
    });

    this.pesquisar();
  }

  private pesquisar(){
    if( this.model.invalid || this._isLoading == true)
      return;

    this._isLoading = true;
    let nomeUsuario:string = this.model.get('nomeUsuario')?.value;

    this.usuarioService.get(nomeUsuario, this._paginaAtual).subscribe({
      next: (usuarioLoginResponse:ListaUsuarioResponse) => {
         
        this._isLoading = false;
        if (usuarioLoginResponse.isError) {
          this.alertService.show("Erro", usuarioLoginResponse.errorDescription, AlertType.error);
          return;
        }

        this._ultimaPagina = true;

        if(this._listaUsuarioResponse == undefined){
          this._listaUsuarioResponse = usuarioLoginResponse;
          this._ultimaPagina = false;
        }
        else if(usuarioLoginResponse.listaUsuario?.length > 0){
          this._listaUsuarioResponse.listaUsuario.push(...usuarioLoginResponse.listaUsuario);
          this._ultimaPagina = false;
        }

        this._dataSource.data = this._listaUsuarioResponse.listaUsuario;
      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this._isLoading = false;
      }
    });
  }

  proximaPagina(){
    this._paginaAtual = this._paginaAtual+1;
    this.pesquisar();
  }

  clickPesquisar(){
    this._paginaAtual = 0;
    this._listaUsuarioResponse = undefined;
    this.pesquisar();
  }

  abrirModalExcluir(idUsuario:number, nomeUsuario:string): void{
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: "Alerta",
        description: `Deseja excluir o usuário ${nomeUsuario}? Essa ação será irreversível.`,
        buttonConfirm: "Confirmar",
        buttonClose: "Cancelar"
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.confirm) 
          this.excluir(idUsuario);
      }
    });
  }

  private excluir(idUsuario:number){

    this.usuarioService.excluir(idUsuario).subscribe({
      next: (returnStruct:ReturnStruct) => {
         
        this._isLoading = false;
        if (returnStruct.isError) {
          this.alertService.show("Erro", returnStruct.errorDescription, AlertType.error);
          return;
        }

        this.clickPesquisar();

        this.alertService.show("Sucesso", "Usuário excluido com sucesso", AlertType.success);
      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this._isLoading = false;
      }
    });
  }


}