import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonService } from '../../common-service';
import { environment } from '../../../../../environments/environment';
import { ReturnStruct } from '../../structs/return.struct';
import { EmpresaResponse } from '../../responses/usuario/empresa.response';
import { EmpresaRequest } from '../../requests/usuario/empresa.request';


@Injectable({
  providedIn: 'root'
})
export class EmpresaService extends CommonService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  public ObterPorId(idEmpresa:number): Observable<EmpresaResponse> {          
      return this.httpClient.get<EmpresaResponse>(environment.urlApiUsuario + `Empresa/idEmpresa/${idEmpresa}`, this.addHeaderToken())
      .pipe(
      catchError(this.handleError)
      )
  }  

  public Alterar(idEmpresa:number, empresaRequest:EmpresaRequest): Observable<ReturnStruct> {    
    return this.httpClient.put<ReturnStruct>(environment.urlApiUsuario + `Empresa/idEmpresa/${idEmpresa}`, empresaRequest,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
 