import { Component } from '@angular/core';
import { ModuloEnum } from '../../../shared/enum/modulo.enum';
import { MenuComponent } from '../../../shared/component/menu/menu.component';
import { FuncionalidadeEnum } from '../../../shared/enum/funcionalidade.enum';

@Component({
  selector: 'app-master-home',
  standalone: true,
  imports: [
    MenuComponent
  ],
  templateUrl: './master-home.component.html',
  styleUrl: './master-home.component.css'
})
export class MasterHomeComponent {
  public moduloEnum:ModuloEnum;
  public funcionalidadeEnum:FuncionalidadeEnum

  constructor(){
    this.moduloEnum = ModuloEnum.master
    this.funcionalidadeEnum = FuncionalidadeEnum.master_usuario
  }
}
